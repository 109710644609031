import { API_BASE_URL } from '../constants/config';
import { getRiskLevel } from '../utils/ipValidation';

export const checkReputation = async (ipToCheck) => {
  // Check delist status first
  const delistResponse = await fetch(`${API_BASE_URL}/checkdelist?ip=${encodeURIComponent(ipToCheck)}`);
  const delistData = await delistResponse.json();

  if (delistData.status === 'pending') {
    return {
      ipAddress: ipToCheck,
      riskLevel: 'PENDIENTE',
      description: 'Solicitud de delistado pendiente de validación.',
      showDelistButton: false,
      noRecords: false
    };
  }

  const response = await fetch(`${API_BASE_URL}/reputation-check?ip=${encodeURIComponent(ipToCheck)}`);
  const data = await response.json();

  const isDeactivated = data.message === 'IP desactivada';
  const noRecords = data.message === 'No se encontraron notas para la IP proporcionada';

  const description = isDeactivated
    ? 'IP desbloqueada y no contiene bloqueos en DNSBL Clisec'
    : (noRecords
      ? `No se encontraron bloqueos para la IP ${ipToCheck}`
      : (data.status === 'RBL'
        ? 'IP Bloqueada en DNSBL Clisec'
        : `IP utilizada ocasionalmente para enviar ${data.status}`));

  const riskLevel = isDeactivated || noRecords
    ? 'BAJO_RIESGO'
    : getRiskLevel(data.status);

  return {
    ipAddress: ipToCheck,
    riskLevel,
    description,
    showDelistButton: !isDeactivated && !noRecords,
    noRecords
  };
};

export const requestDelist = async (ipAddress, recaptchaToken, userData) => {
  // Check if IP is already in pending status
  const delistResponse = await fetch(`${API_BASE_URL}/checkdelist?ip=${encodeURIComponent(ipAddress)}`);
  const delistData = await delistResponse.json();

  if (delistData.status === 'pending') {
    throw new Error('Esta IP ya tiene una solicitud de desbloqueo pendiente.');
  }

  if (delistData.status === 'not_pending') {
    throw new Error(`No se encontraron bloqueos para la IP ${ipAddress}`);
  }

  // Proceed with delist request
  const response = await fetch(`${API_BASE_URL}/delist`, {
    method: 'POST',
    headers: {
      'Content-Type': 'application/json',
    },
    body: JSON.stringify({
      ipAddress,
      recaptchaToken,
      name: userData.name,
      email: userData.email,
      comment: userData.comment,
    }),
  });

  if (!response.ok) {
    throw new Error('Error al solicitar desbloqueo');
  }

  const data = await response.json();
  return data;
};

