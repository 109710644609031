export const isValidIPv4 = (ip) => {
  const ipv4Regex = /^(25[0-5]|2[0-4][0-9]|[01]?[0-9][0-9]?)\.(25[0-5]|2[0-4][0-9]|[01]?[0-9][0-9]?)\.(25[0-5]|2[0-4][0-9]|[01]?[0-9][0-9]?)\.(25[0-5]|2[0-4][0-9]|[01]?[0-9][0-9]?)$/;
  return ipv4Regex.test(ip);
};

export const isPrivateIP = (ip) => {
  const privateIPRanges = [
    /^10\./,
    /^172\.(1[6-9]|2[0-9]|3[0-1])\./,
    /^192\.168\./,
    /^127\./,
    /^169\.254\./,
  ];
  return privateIPRanges.some((range) => range.test(ip));
};

export const getRiskLevel = (status) => {
  if (status === 'RBL') return 'ALTO_RIESGO';
  switch (status) {
    case 'spam':
    case 'phishing':
    case 'troyano':
    case 'ransomware':
    case 'virus':
      return 'ALTO_RIESGO';
    case 'marketing':
    case 'rbl':
      return 'RIESGO_MEDIO';
    default:
      return 'BAJO_RIESGO';
  }
};
