import React from 'react';

const Navbar = () => {
    return (
        <nav
            className="navbar navbar-expand-lg fixed-top"
        >
            <div className="container">
                {/* <a href="/" className="navbar-brand d-flex align-items-center">
                    <img
                        src="https://check.clisec.cl/assets/img/logo.svg"
                        alt="Clisec Logo"
                        className="me-2"
                        style={{
                            width: '100px',
                            height: 'auto',
                        }}
                    />
                    <span className="fw-bold text-light text-uppercase">Clisec</span>
                </a> */}
                <button
                    className="navbar-toggler"
                    type="button"
                    data-bs-toggle="collapse"
                    data-bs-target="#navbarNav"
                    aria-controls="navbarNav"
                    aria-expanded="false"
                    aria-label="Toggle navigation"
                >
                    <span className="navbar-toggler-icon"></span>
                </button>
                <div className="collapse navbar-collapse" id="navbarNav">
                    <ul className="navbar-nav ms-auto">
                        <li className="nav-item">
                            <a
                                href="https://clisec.cl"
                                target="_blank"
                                className="nav-link text-light"
                                style={{ transition: 'color 0.3s ease' }}
                                onMouseOver={(e) => (e.target.style.color = '#1db954')}
                                onMouseOut={(e) => (e.target.style.color = 'white')}
                            >
                                Clisec Antispam for cPanel
                            </a>
                        </li>
                        <li className="nav-item">
                            <a
                                href="https://app.clisec.cl"
                                className="btn ms-3"
                                target="_blank"
                                style={{
                                    background: 'linear-gradient(90deg, #3f3eed, #3f3eed)',
                                    border: 'none',
                                    color: 'white',
                                    padding: '8px 16px',
                                    borderRadius: '20px',
                                    fontWeight: 'bold',
                                    transition: 'transform 0.3s ease',
                                }}
                                onMouseOver={(e) => (e.target.style.transform = 'scale(1.1)')}
                                onMouseOut={(e) => (e.target.style.transform = 'scale(1)')}
                            >
                                Portal clientes
                            </a>
                        </li>
                    </ul>
                </div>
            </div>
        </nav>
    );
};

export default Navbar;
