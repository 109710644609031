import React from 'react';
import { Alert } from 'react-bootstrap';
import { useReputationCheck } from './hooks/useReputationCheck';
import Header from './components/Header';
import SearchForm from './components/SearchForm';
import ResultsTable from './components/ResultsTable';
import Navbar from './components/Navbar';

const ReputationChecker = () => {
  const {
    ipAddress,
    setIpAddress,
    results,
    error,
    loading,
    delistLoading,
    recaptchaToken,
    setRecaptchaToken,
    handleSubmit,
    handleDelist,
    showDelistModal,
    handleDelistClick,
    handleCloseModal
  } = useReputationCheck();

  return (
    <>
      <Navbar />
      <div
        className="banner-area banner-style-three text-light text-default section-small-platform-2"
        style={{
          backgroundImage: "url(assets/img/stars_stream.svg)",
          backgroundSize: "cover",
          backgroundPosition: "center",
          minHeight: "100vh",
          display: "flex",
          justifyContent: "center",
          alignItems: "center",
          padding: "20px",
        }}
      >
        <div className="container">
          <div className="row justify-content-center">
            <div className="col-lg-10 col-md-12">
              <Header />
              
              <SearchForm 
                ipAddress={ipAddress}
                onIpChange={setIpAddress}
                onSubmit={handleSubmit}
                loading={loading}
                onTryExample={(ip) => handleSubmit(null, ip)}
              />

              {error && <Alert variant="danger">{error}</Alert>}
              
              <ResultsTable 
                results={results}
                delistLoading={delistLoading}
                recaptchaToken={recaptchaToken}
                onReCAPTCHAChange={setRecaptchaToken}
                onDelistClick={handleDelistClick}
                showDelistModal={showDelistModal}
                onCloseModal={handleCloseModal}
                onDelist={handleDelist}
              />
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default ReputationChecker;
