import React from 'react';

const Header = () => {
  return (
    <div className="text-center mb-4">
      <a 
        href="https://clisec.cl" 
        className="d-flex flex-column align-items-center" 
        target="_blank" 
        rel="noreferrer"
      >
        <img src="/assets/img/logo.svg" alt="Clisec" className="mb-3" />
      </a>
      {/* <h6>Clisec Cybersecurity</h6> */}
      <h2 className="mb-3">Reputation Checker</h2>
      <p>
        ¿Tienes problemas para enviar correos electrónicos? <br />
        ¿Necesitas comprobar si una dirección IP se encuentra bloqueada?
      </p>
    </div>
  );
};

export default Header;
