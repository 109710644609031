import React from 'react';
import { Form, Button, Spinner } from 'react-bootstrap';

const SearchForm = ({ 
  ipAddress, 
  onIpChange, 
  onSubmit, 
  loading,
  onTryExample 
}) => {
  return (
    <>
      <Form
        id="reputation-check-form"
        className="mb-4"
        onSubmit={onSubmit}
      >
        <Form.Group className="mb-3">
          <Form.Control
            type="text"
            placeholder="Ingrese una dirección IP"
            className="text-center"
            value={ipAddress}
            onChange={(e) => onIpChange(e.target.value)}
            required
          />
        </Form.Group>
        <div id="submit-container">
          <Button type="submit" disabled={loading}>
            {loading ? (
              <Spinner animation="border" size="sm" />
            ) : (
              "Buscar"
            )}
          </Button>
        </div>
      </Form>
      <div className="text-center mb-3">
        <Button
          variant="secondary"
          onClick={() => onTryExample('8.8.8.8')}
        >
          Try 8.8.8.8
        </Button>
      </div>
    </>
  );
};

export default SearchForm;
