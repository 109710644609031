import React from 'react';
import { Table, Badge, Button } from 'react-bootstrap';
import DelistModal from './DelistModal';

const ResultsTable = ({ 
  results, 
  delistLoading, 
  recaptchaToken, 
  onReCAPTCHAChange,
  onDelistClick,
  showDelistModal,
  onCloseModal,
  onDelist
}) => {
  if (!results) return null;

  return (
    <div className="mt-4 text-center">
      <h3 className="mb-3">
        Resultados para{" "}
        <span className="font-weight-bold">{results.ipAddress}</span>
      </h3>
      <div className="table-responsive">
        <Table style={{ color: "white" }}>
          <tbody>
            <tr>
              <th>Nivel de Riesgo:</th>
              <td>
                <Badge
                  bg={
                    results.riskLevel === "ALTO_RIESGO"
                      ? "danger"
                      : results.riskLevel === "RIESGO_MEDIO"
                      ? "warning"
                      : "success"
                  }
                >
                  {results.riskLevel}
                </Badge>
              </td>
            </tr>
            <tr>
              <th>Descripción:</th>
              <td>{results.description}</td>
            </tr>
          </tbody>
        </Table>
      </div>
                {results.showDelistButton && (
                  <div className="mt-3">
                    <Button
                      variant="warning"
                      onClick={onDelistClick}
                      className="mt-3"
                    >
                      Solicitar Desbloqueo
                    </Button>
                  </div>
                )}

                <DelistModal
                  show={showDelistModal}
                  onHide={onCloseModal}
                  onSubmit={onDelist}
                  loading={delistLoading}
                  recaptchaToken={recaptchaToken}
                  onReCAPTCHAChange={onReCAPTCHAChange}
                />
    </div>
  );
};

export default ResultsTable;
