import React, { useState } from 'react';
import { Modal, Form, Button, Spinner } from 'react-bootstrap';
import ReCAPTCHA from "react-google-recaptcha";
import { RECAPTCHA_SITE_KEY } from '../constants/config';

const DelistModal = ({ 
  show, 
  onHide, 
  onSubmit, 
  loading,
  recaptchaToken,
  onReCAPTCHAChange 
}) => {
  const [formData, setFormData] = useState({
    name: '',
    email: '',
    comment: ''
  });

  const handleChange = (e) => {
    const { name, value } = e.target;
    setFormData(prev => ({
      ...prev,
      [name]: value
    }));
  };

  const handleSubmit = (e) => {
    e.preventDefault();
    onSubmit(formData);
  };

  return (
    <Modal
      show={show}
      onHide={onHide}
      centered
      backdrop="static"
      className="delist-modal"
    >
      <Modal.Header closeButton>
        <Modal.Title>Solicitud de Desbloqueo</Modal.Title>
      </Modal.Header>
      <Modal.Body>
        <Form onSubmit={handleSubmit}>
          <Form.Group className="mb-3">
            <Form.Label>Nombre</Form.Label>
            <Form.Control
              type="text"
              name="name"
              value={formData.name}
              onChange={handleChange}
              required
              placeholder="Ingrese su nombre"
            />
          </Form.Group>

          <Form.Group className="mb-3">
            <Form.Label>Email</Form.Label>
            <Form.Control
              type="email"
              name="email"
              value={formData.email}
              onChange={handleChange}
              required
              pattern="[a-z0-9._%+-]+@[a-z0-9.-]+\.[a-z]{2,}$"
              placeholder="ejemplo@dominio.com"
            />
          </Form.Group>

          <Form.Group className="mb-3">
            <Form.Label>Motivo desbloqueo</Form.Label>
            <Form.Control
              as="textarea"
              name="comment"
              value={formData.comment}
              onChange={handleChange}
              required
              maxLength={200}
              placeholder="Ingrese un comentario (máximo 200 caracteres)"
              rows={3}
            />
            <Form.Text className="text-muted">
              {formData.comment.length}/200 caracteres
            </Form.Text>
          </Form.Group>

          <div className="d-flex flex-column align-items-center">
            <ReCAPTCHA
              sitekey={RECAPTCHA_SITE_KEY}
              onChange={onReCAPTCHAChange}
            />
            <Button
              variant="primary"
              type="submit"
              disabled={loading || !recaptchaToken}
              className="mt-3 w-100"
            >
              {loading ? (
                <Spinner animation="border" size="sm" />
              ) : (
                "Enviar Solicitud"
              )}
            </Button>
          </div>
        </Form>
      </Modal.Body>
    </Modal>
  );
};

export default DelistModal;
