import { useState, useCallback, useEffect } from 'react';
import { isValidIPv4, isPrivateIP } from '../utils/ipValidation';
import { checkReputation, requestDelist } from '../services/reputationService';

export const useReputationCheck = () => {
  const [ipAddress, setIpAddress] = useState('');
  const [results, setResults] = useState(null);
  const [error, setError] = useState('');
  const [loading, setLoading] = useState(false);
  const [delistLoading, setDelistLoading] = useState(false);
  const [recaptchaToken, setRecaptchaToken] = useState(null);
  const [showDelistModal, setShowDelistModal] = useState(false);

  const handleSubmit = useCallback(async (event, ipToCheck = ipAddress) => {
    if (event) event.preventDefault();
    setLoading(true);
    setError('');
    setResults(null);

    if (!isValidIPv4(ipToCheck) || isPrivateIP(ipToCheck)) {
      setError('Dirección IP no válida');
      setLoading(false);
      return;
    }

    try {
      const result = await checkReputation(ipToCheck);
      setResults(result);
    } catch (error) {
      console.error('Error al obtener los datos:', error);
      setError('Error al obtener la información de la dirección IP. Por favor, inténtalo de nuevo más tarde.');
    } finally {
      setLoading(false);
    }
  }, [ipAddress]);

  const handleDelistClick = () => {
    // Si ya hay una solicitud pendiente, no mostramos el modal
    if (results?.description === 'Solicitud pendiente de aprobación.') {
      setError('Esta IP ya tiene una solicitud de desbloqueo pendiente.');
      return;
    }
    setShowDelistModal(true);
  };

  const handleCloseModal = () => {
    setShowDelistModal(false);
    setRecaptchaToken(null);
  };

  const handleDelist = async (userData) => {
    if (!recaptchaToken) {
      setError('Por favor, complete el captcha antes de solicitar el desbloqueo.');
      return;
    }

    setDelistLoading(true);
    setError('');
    try {
      const data = await requestDelist(ipAddress, recaptchaToken, userData);

      if (data.message) {
        // Actualizamos el estado manualmente para mostrar que la solicitud está pendiente
        setResults(prevResults => ({
          ...prevResults,
          description: 'Solicitud pendiente de aprobación.',
          showDelistButton: false
        }));
        handleCloseModal();
      } else {
        setError('Error al solicitar desbloqueo');
      }
    } catch (error) {
      console.error('Error en la solicitud:', error);
      setError('Error al solicitar desbloqueo');
    } finally {
      setDelistLoading(false);
      setRecaptchaToken(null);
    }
  };

  useEffect(() => {
    const path = window.location.pathname;
    const ipFromPath = path.split('/').pop();
    if (isValidIPv4(ipFromPath)) {
      setIpAddress(ipFromPath);
      handleSubmit(null, ipFromPath);
    }
  }, [handleSubmit]);

  return {
    ipAddress,
    setIpAddress,
    results,
    error,
    loading,
    delistLoading,
    recaptchaToken,
    setRecaptchaToken,
    handleSubmit,
    handleDelist,
    showDelistModal,
    handleDelistClick,
    handleCloseModal
  };
};
